import qs from 'qs'
import axios from 'axios';

// import Router from '@/router/index.js'

import { Message } from 'element-ui';

import global from '@/utils/global.js'

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/api' : ''

axios.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  config.headers['token'] = global.token
  return config
},error => {
  return Promise.reject(error)
})


axios.interceptors.response.use(response => {
  if (response.data.resCode === -1 ) {
    global.logout()
  }else if(response.data.resCode === -2 ) {
    Message.error(response.data.msg)
  }
  return response
},error => {
  Message.error('网络异常:'+error.response.status)
  return Promise.reject(error)
})

const handleResponse = (data) => {
  if(data.data.resCode < 0) return Object.assign(data, { success: false })
  return Object.assign(data, { success: true })
}

// post 请求 表单格式
export const apiFormPostData = async (url, params = {}) => handleResponse(await axios.post(url, qs.stringify(params),  { headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}} ))

// post 请求 json格式
export const apiPostData = async (url, params = {}) => handleResponse(await axios.post(url, params))

// 上传post文件
export const apiPostFile = async (url, params ={}) => handleResponse(await axios.post(url, params, { headers: {'Content-Type': 'multipart/form-data' }}))

// 登录界面获取
export const apiGetDesktop = () => apiPostData('/admin/main/getDesktop')

// 清理缓存数据
export const apiClearCache = () => apiPostData('/admin/system/clearCache')

// 一键登录商家
export const apiGetUerLogin = (params) => apiPostData('/admin/user/getUserlogin', params) 

// 登录
export const apiLogin = (params) => apiPostData('/admin/main/login', params)

//后台用户初始化
export const apiInit = () => apiPostData('/admin/system/init')

// 获取配置
export const apiGetConfig = () => apiPostData('/admin/config/getData') 

// 获取websocket前缀
export const apiGetWSPrefix = () => apiPostData('/system/main/getWSPrefix') 


// 获取菜单列表
export const apiGetMenuList = () => apiPostData('/admin/system/getMenuList')

//保存菜单
export const apiSaveMenu = (params) => apiPostData('/admin/system/saveMenu', params)

//菜单删除
export const apiDelMenu = (params) => apiPostData('/admin/system/delMenu', params)

//获取角色列表
export const apiGetRoleList = (params) => apiPostData('/admin/system/getRoleList', params)

//保存角色
export const apiSaveRole = (params) => apiPostData('/admin/system/saveRole', params)

//删除角色
export const apiDelRole = (params) => apiPostData('/admin/system/delRole', params)

//获取角色权限菜单
export const apiGetRoleMenu = (params) => apiPostData('/admin/system/getRoleMenu', params)

//保存角色菜单权限信息
export const apiSaveRoleMenu = (params) => apiPostData('/admin/system/saveRoleMenu', params)

// 获取管理员列表
export const apiGetManageList = (params) => apiPostData('/admin/manage/getList', params)

//保存管理员
export const apiSaveManage = (params) => apiPostData('/admin/manage/save', params)

// 日志列表
export const apiGetLogList = (params) => apiPostData('/admin/system/getLogList', params)

// 获取系统配置
export const apiGetSystemConfig = (params) => apiPostData('/admin/config/getData', params)

// 修改系统配置
export const apiSetSystemConfig = (params) => apiPostData('/admin/config/save', params)

// 上传
export const apiUploadFile = (params) => apiPostFile('/admin/upload/img', params)

// 平台类型列表
export const apiGetShopTypeList = (params) =>  apiPostData('/admin/shop/getPlatformList', params)

// 获取店铺列表
export const apiGetShopList = (params) => apiPostData('/admin/shop/getShopList', params)

// 添加店铺
export const apiSaveShop = (params) => apiPostData('/admin/shop/saveShop', params)   

// 店铺审核
export const apiUpdateStoreStatus = (params) => apiPostData('/admin/shop/updateStatus', params)   

// 发送手机验证码
export const apiSendCode = (params) => apiPostData('/home/main/getMobileCode', params)

// 注册
export const apiReg = (params) =>  apiPostData('/admin/main/reg', params)

// 获取发布任务配置
export const apiGetTaskConfig = (params) => apiPostData('/admin/task/getConfig', params)


// 平均模式 分配时间
export const apiGetAverageOrderTime = (params) =>  apiPostData('/admin/task/getAverageOrderTime', params)


// 加入IP黑名单
export const apiAddIpBlack = (params) => apiPostData('/admin/system/addIpBlack', params) 

// 获取IP黑名单列表
export const apiGetIpBackList = (params) => apiPostData('/admin/shop/getShopList', params)

// 删除IP黑名单
export const apiDelIpBlack = (params) =>  apiPostData('/admin/system/delIpBlack', params)


// 商家 发布垫付任务
export const apiReleaseTask = (params) => apiPostData('/admin/task/release', params)   

// 获取任务列表
export const apiGetTaskList = (params) => apiPostData('/admin/task/getTaskList', params)  

// 商家 任务订单列表
export const apiGetTaskOrderList = (params) => apiPostData('/admin/task/getTaskOrderList', params) 

// 获取任务详情
export const apiGetTaskItemInfo = (params) => apiPostData('/admin/task/getTaskItem', params) 

// 管理员 任务通过审核
export const apiTaskPass = (params) => apiPostData('/admin/task/approval', params) 

// 管理员 任务审核不通过
export const apiTaskNoPass = (params) => apiPostData('/admin/task/disagree', params) 

// 商家 任务支付
export const apiTaskPay = (params) => apiPostData('/admin/task/pay', params)


// 商家 获取前台账号列表
export const apiGetAccountList = (params) => apiPostData('/admin/account/getAccountList', params) 

// 账号审核通过
export const apiAccountPass = (params) => apiPostData('/admin/account/accountAdopt', params) 

// 账号审核不通过
export const apiAccountNoPass = (params) => apiPostData('/admin/account/accountFail', params) 

// 获取提现申请列表
export const apiGetCashList = (params) => apiPostData('/admin/capital/getCashList', params)  

// 任务订单审核通过 不通过接口
export const apiOrderReview = (params) => apiPostData('/admin/task/orderReview', params)


// 管理员 获取实名审核列表
export const apiGetReaList = (params) => apiPostData('/admin/user/getReaList', params) 

// 实名审核通过 不通过
export const apiRealUpStatus = (params) => apiPostData('/admin/user/realUpStatus', params)

// 商家确认返款
export const apiConfirmRefund = (params) => apiPostData('/admin/task/confirmRefund', params)

// 商家确认完成
export const apiTaskOrderEnd = (params) => apiPostData('/admin/task/taskOrderEnd', params) 

// 获取用户 资金流水记录列表
export const apiGetUserMoneyList = (params) => apiPostData('/admin/capital/getUserMoneyList', params) 

// 获取商家 资金流水记录列表
export const apiGetShopMoneyList = (params) => apiPostData('/admin/capital/getShopMoneyList', params) 

//提现审核获取用户近30天资金流水记录
export const apiGetUserMoneyDay30 = (params) => apiPostData('/admin/capital/getUserMoneyDay30', params) 

// 提现审核通过
export const apiCashApproved = (params) => apiPostData('/admin/capital/cashApproved', params) 

// 提现审核不通过
export const apiCashFail= (params) => apiPostData('/admin/capital/cashFail', params) 

// 提现确认到账
export const apiCashSuccess = (params) => apiPostData('/admin/capital/cashSuccess', params) 

// 获取买家列表
export const apiGetBuyList = (params) => apiPostData('/admin/user/getBuyList', params) 

// 推广状态开关
export const apiSetBuySpreadStatus = (params) =>  apiPostData('/admin/user/setBuySpreadStatus', params) 

// 新增骗子
export const apiAddPianzi = (params) => apiPostData('/admin/black/addBlack', params) 

// 获取骗子库黑名单列表
export const apiGetBlackList = (params) => apiPostData('/admin/black/getBlackList', params) 

// 店铺黑名单列表
export const  apiGetUserShopBlackList = (params) => apiPostData('/admin/black/getUserShopBlackList', params) 

// 商家账号黑名单列表
export const  apiGetStoreBlackList = (params) => apiPostData('/admin/black/getUserBlackList', params) 

// 用户列表 加入黑名单
export const apiAddUserBlock = (params) => apiPostData('/admin/black/userBlock', params) 

// 用户列表 加入骗子库
export const apiUserAddPianzi = (params) => apiPostData('/admin/black/userAddBlack', params) 

// 商家 待返款状态驳回
export const apiCancelRefund = (params) => apiPostData('/admin/task/cancelRefund', params)

// 获取全部任务金额配置列表
export const apiGetTaskMoneyConfig = (params) => apiPostData('/admin/task/getTaskConfig', params)

// 保存修改任务配置
export const apiSaveTaskConfig = (params) => apiPostData('/admin/task/saveTaskConfig', params)

// 移出骗子库
export const apiRemoveBlack = (params) => apiPostData('/admin/black/removeBlack', params)

// 商家充值
export const apiAddRecharge = (params) => apiPostData('/admin/capital/addRecharge', params) 

// 获取商家余额
export const apiGetUserBalance =  (params) => apiPostData('/admin/user/getUserBalance', params) 

// 获取商家充值列表
export const apiGetRechargeList =  (params) => apiPostData('/admin/capital/getRechargeList', params) 

// 充值审核通过
export const apiRechargeSuccess = (params) => apiPostData('/admin/capital/rechargeSuccess', params) 

// 充值审核不通过
export const apiRechargeFail = (params) => apiPostData('/admin/capital/rechargeFail', params)

// 商家后台首页数据
export const apiShopInfoTotal = (params) => apiPostData('/admin/user/shopInfoTotal', params) 

// 修改买家金额
export const apiEditBuyMoney = (params) => apiPostData('/admin/user/editBuyMoney', params)

// 修改商家家金额
export const apiEditShopMoney = (params) => apiPostData('/admin/user/editShopMoney', params)

// 获取商家列表
export const apiGetBussinessList = (params) => apiPostData('/admin/user/getShopList', params) 

// 修改商家推广状态
export const apiSetShopSpreadStatus = (params) => apiPostData('/admin/user/setShopSpreadStatus', params)  

// 修改商家信息
export const apiShopUserEdit = (params) => apiPostData('/admin/user/shopUserEdit', params) 

// 商家封号/解封
export const apiSetShopStatus = (params) => apiPostData('/admin/user/setShopStatus', params) 
 
// 修改用户（买家的）信息 密码
export const apiBuyUserEdit = (params) => apiPostData('/admin/user/buyUserEdit', params)  

// 获取管理员列表 时 获取角色列表
export const apiGetRole = (params) => apiPostData('/admin/system/getRole', params) 

// 取消任务订单
export const apiCancelTaskOrder = (params) => apiPostData('/admin/task/cancelTaskOrder', params)  

// 取消任务
export const apiCancelTask = (params) => apiPostData('/admin/task/cancelTask', params)   

// 获取平台佣金配置列表
export const apiGetPlatformCoinfig = (params) => apiPostData('/admin/platform/getPlatformCoinfig', params) 

// 修改平台佣金
export const apiEditPlatformConfig = (params) => apiPostData('/admin/platform/editPlatformConfig', params) 

// 获取店铺平台佣金配置
export const apiGetShopPlatConfig = (params) => apiPostData('/admin/shop/getShopConfig', params) 

// 编辑店铺平台佣金设置
export const apiEditShopPlatConfig = (params) => apiPostData('/admin/shop/editShopConfig', params) 

// 获取商家公告列表
export const apiGetShopBulletinList = (params) => apiPostData('/admin/bulletin/getBulletinList', params)  

// 商家公告新增编辑
export const apiSaveBulletin = (params) => apiPostData('/admin/bulletin/saveBulletin', params)

// 删除商家公告
export const apiDelShopBulletin = (params) => apiPostData('/admin/bulletin/delBulletin', params)

// 商家修改密码
export const apiChangeShopPassword  = (params) => apiPostData('/admin/user/changePassword', params)

// 商家发布任务基础佣金获取
export const apiGetBasePrice = (params) => apiPostData('/admin/task/getBasePrice', params) 

// 获取银行卡列表
export const apiGetBankList = (params) => apiPostData('/admin/system/getBankList', params) 

// 添加 编辑 银行卡
export const apiSaveBank = (params) => apiPostData('/admin/system/saveBank', params) 

// 删除银行卡
export const apiDelBank = (params) => apiPostData('/admin/system/delBank', params)

// 获取用户提现账号列表
export const apiGetUserBankList = (params) =>  apiPostData('/admin/user/getUserBankList', params)

// 导出用户提现记录
export const apiExportCash = (params) => apiPostData('/admin/capital/exportCash', params) 

// 导出商家资金流水
export const apiExportShopMoney = (params) => apiPostData('/admin/capital/exportShopMoney', params)  

// 导出用户资金流水
export const apiExportUserMoney = (params) => apiPostData('/admin/capital/exportUserMoney', params)  

// 导出商家充值记录
export const apiExportRecharge = (params) => apiPostData('/admin/capital/exportRecharge', params)   

// 批量导入骗子库
export const apiPianziImportBlack = (params) => apiPostFile('/admin/black/importBlack', params)  

// 导出骗子库
export const apiPianziExportBlack= (params) => apiPostData('/admin/black/exportBlack', params)  

// 获取任务统计
export const apiTaskTotalGetList = (params) =>  apiPostData('/admin/report/getReportList', params)   

// 获取任务统计商家列表
export const apiGetShopReportList = (params) => apiPostData('/admin/report/getShopReportList', params)   

// 管理员获取首页数据
export const apiGetAdminTotalReport = (params) =>  apiPostData('/admin/report/getTotalReport', params)    

// 商家 获取首页公告列表
export const apiGetMainShopBulletinList = (params) =>  apiPostData('/admin/bulletin/getShopBulletinList', params)   

// 批量确认提现到账
export const apiBatchCashSuccess = (params) => apiPostData('/admin/capital/batchCashSuccess', params)    

// 后台 垫付任务订单列表 获取接取任务列表
export const apiGetUserOrderList = (params) => apiPostData('/admin/task/getUserOrderList', params)     

// 移出店铺黑名单
export const apiDelShopBlack = (params) => apiPostData('/admin/black/delShopBlack', params)     

// 任务订单导出
export const apiExportTaskOrderAdmin = (params) => apiPostData('/admin/task/exportTaskOrder', params)

// 取消操作中订单
export const apiExportCancelOrder = (params) => apiPostData('/admin/task/cancelOrder', params) 

// 删除用户账号
export const apiDelAccount = (params) => apiPostData('/admin/account/delAccount', params)  

// 新增平台佣金配置
export const apiAddPlatformConfig = (params) => apiPostData('/admin/platform/addPlatformConfig', params)  

// 获取代理列表
export const apiGetAgentList = (params) => apiPostData('/admin/user/getAgentList', params)   

// 增加/编辑代理
export const apiSaveAgent = (params) => apiPostData('/admin/user/saveAgent', params)   

// 修改代理的钱
export const apiEditAgentMoney = (params) => apiPostData('/admin/user/editAgentMoney', params)   

// 封代理
export const apiSetAgentStatus = (params) => apiPostData('/admin/user/setAgentStatus', params)   

// 代理提现列表
export const apigetAgentCashList = (params) => apiPostData('/admin/capital/getAgentCashList', params) 

// 获取任务订单列表状态的tablist
export const apGetTaskOrderStatusTab = (params) => apiPostData('/admin/task/getTaskOrderStatusTab', params) 


// 获取发货的礼品列表信息和快递站信息
export const apiGetExpressList = (params) => apiPostData('/admin/express/getExpressList', params) 

// 单个发货
export  const apiCreateOrder = (params) => apiPostData('/admin/express/createOrder', params)  

// 批量发货
export const apiBatchCreateOrder = (params) => apiPostData('/admin/express/batchCreateOrder', params) 

// 获取买家黑名单列表
export const apiGetBuyBlackList = (params) => apiPostData('/admin/black/getShopBuyBlackList', params)

// 移除买家黑名单列表
export const apiDelBuyBlack = (params) => apiPostData('/admin/black/delShopBuyBlack', params)

// 新增商家账号黑名单列表
export const apiAddStoreAccountBlack = (params) => apiPostData('/admin/black/addUserBlack', params)

// 新增店铺黑名单
export const apiAddShopBlack = (params) => apiPostData('/admin/black/addShopBlack', params) 

// 移除商家账号黑名单
export const apiDelStoreAccountBlack = (params) => apiPostData('/admin/black/delUserBlack', params)

// 获取用户手机验证码功能
export const apiGetUserMobileCode = (params) => apiPostData('/admin/user/getUserMobileCode', params)

// 配置商家店铺平台服务费
export const apiConfigStoreConfigServer = (params) => apiPostData('/admin/shop/editShop', params)

// 新增商家
export const apiAddBusiness = (params) => apiPostData('/admin/user/shopReg', params)

// 新增用户
export const apiAddUser = (params) => apiPostData('/admin/user/buyReg', params)